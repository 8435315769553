/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://creativecommons.org/licenses/by/3.0/"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 88px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 35.22727272727273%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABwElEQVQoz3WQS08aYRSGP7Ys2NiEjf4BdizYaDHara6mWxsvFAeljZcREyZpyiWRsSmoZRBmbIYEIjgZEI1+WG8lbJwudONOfwKxO+ryexu+6MI0XZy8i+fkyTkvkT9FH6tWBRPvJpjwVkBoXsSeVYVuaPiW38JmbgMbagY5XcWqHEEgGMDsXBCZbBpb25ucZ7IZtr2Tw8fFD3+IbuhYkpZYzaqh0+kgn89Diki4ub0GPT/GQbOB47MjFHcNrH9Zh2XVUC6VkfqaQvOCokH30aB1nFw2mZJOgUzNTDFFUUApZaZpot1uQ5ZlFMtFnLVOUa1XuDSRiqPVaiGZTMK+shFLfIZ1aHJeqe2yfVpHfC3OyOT0JFRVRTgchiAIKBQKMAwD2ncNv25sLjs6PYR5sIdYIsZ574Deq81Lynnvyh8/T6CkFZDeu1E5ivu7e9btdmHbNsSQiFKlBM0o8J6eO4yvxRAKi4hEV6BqWS596ph3uCgtMDIw0A9xTuRSfUfHsrSM98EAXC4XCCEvxu/3Y2x8DF6v9x9GCGFPSX73verD6JtRNvR6EMMjw3A6nXzJ4XC8SJ/PB4/HA7fb/T/Zw184/0pp27wtWgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Licencia Creative Commons\"\n        title=\"Licencia Creative Commons\"\n        src=\"/static/ec8a466729574b8bf747a24f30f9e1dd/942f4/88x31.png\"\n        srcset=\"/static/ec8a466729574b8bf747a24f30f9e1dd/942f4/88x31.png 88w\"\n        sizes=\"(max-width: 88px) 100vw, 88px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "He cambiado la licencia de este blog para aumentar las libertades de todos, permitiendo usos comerciales de la información y realizar obras derivadas de todo sin necesidad de compartir con la misma licencia, creo que cerrar libertades sólo favorece a los que más poder tienen y de esta forma pongo a disposición de todo el mundo lo que hago aquí."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
